html, body, #root, .app, .map {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.map {
    position: relative;
}

.leaflet-marker-icon {
    filter: grayscale(100%);
}

html {
    font-size: 18px;
}

@media only screen and (max-width: 1255px) {
    html {
        font-size: 14px;
    }
}
